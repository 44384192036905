import React from 'react';
import SplashScreen from 'presential/splash-screen/SplashScreen';
import './ScreenStart.scss';

const ScreenStart = (): React.ReactElement => {
  return (
    <div className="screen-start">
      <div className="screen-start__background-sky" />
      <SplashScreen />
    </div>
  );
};
export default ScreenStart;
