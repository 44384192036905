import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import SVGLoader from 'component/svg/SVG';
import MirrorSplashScreen from 'presential/mirror-splash-screen/MirrorSplashScreen';
import { EConsumption } from 'enum/user';
import MirrorModalSkin from 'component/mirror-modal-skin/MirrorModalSkin';
import DeepARMirror from 'component/deepar-mirror/DeepARMirror';
import { MiroirSVG } from 'services/exports-list';
import userInteraction from 'services/user-interaction';
import sceneInteraction from 'services/scene-interaction';
import { EScene } from 'enum/scene';

import './ActivityMirror.scss';

interface IProps {
  isAfterMirror?: boolean;
}

const ActivityMirror = ({ isAfterMirror = false }: IProps): React.ReactElement => {
  const [currentIndex, setIndex] = useState<number>(1);
  const [currentSkinFilter, setSkinFilter] = useState<number>(0);
  const userConsumptionType: EConsumption = userInteraction.getUserConsumption();

  return (
    <div className="activity-mirror">
      <div className="activity-mirror__mirror">
        <SVGLoader src={MiroirSVG} className="activity-mirror__mirror-img" />
        <div className="activity-mirror__mirror-content">
          <Modal
            closeAfterTransition
            disableEscapeKeyDown={false}
            onBackdropClick={(): boolean => {
              if (!isAfterMirror) {
                return false;
              }
              sceneInteraction.goToScreen(EScene.START);
              return false;
            }}
            open={currentIndex === 1 && userConsumptionType !== EConsumption.VAPER}
          >
            <SwitchTransition>
              <CSSTransition
                key={currentIndex}
                addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
                classNames="fade"
              >
                <div className="activity-mirror__modal modal">
                  {currentIndex === 1 && (
                    <MirrorModalSkin
                      onClick={(skinIndex) => {
                        setIndex(currentIndex + 1);
                        setSkinFilter(skinIndex);
                      }}
                    />
                  )}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </Modal>
          <SwitchTransition>
            <CSSTransition
              key={currentIndex}
              addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
              classNames="fade"
            >
              <>
                {currentIndex === 0 && <MirrorSplashScreen onClick={() => setIndex(currentIndex + 1)} />}
                {(currentIndex >= 2 || (currentIndex >= 1 && userConsumptionType === EConsumption.VAPER)) && (
                  <DeepARMirror isUnmount={isAfterMirror} selectedSkinIndex={currentSkinFilter} />
                )}
              </>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  );
};
export default ActivityMirror;
