// noinspection SpellCheckingInspection,JSUnusedGlobalSymbols

import CocktailSign from 'images/cocktail-sign-all.svg';
import CocktailMenthEur from 'images/cocktail-menth-eur.svg';
import CocktailNameMenthEur from 'images/cocktail-name-menth-eur.svg';
import CocktailNicotineOnTheRock from 'images/cocktail-nicotine-on-the-rock.svg';
import CocktailNameNicotineOnTheRock from 'images/cocktail-name-nicotine-on-the-rock.svg';
import CocktailNameLaVeuveNoire from 'images/cocktail-name-veuve-noire.svg';
import CocktailLaVeuveNoire from 'images/cocktail-la-veuvenoire.svg';
import CocktailLeRainbowShot from 'images/cocktail-le-rainbow-shot.svg';
import CocktailNameLeRainbowShot from 'images/cocktail-name-rainbow-shot.svg';
import CocktailLeSmokeyEye from 'images/cocktail-le-smokey-eye.svg';
import CocktailNameLeSmokeyEye from 'images/cocktail-name-smokey-eye.svg';
import CocktailZombie from 'images/cocktail-zombie.svg';
import CocktailNameZombie from 'images/cocktail-name-zombie.svg';
import Billet from 'images/Nicobar-assets-billet.svg';
import Defis from 'images/defi.svg';
import SingleLogo from 'images/logo-single.svg';
import SingleLogoPresential from 'images/logo-single-presential.svg';
import TitledCoin from 'images/tilted-coin.svg';
import Coin from 'images/coin.svg';
import InterieurBar from 'images/interieur-bar.svg';
import wallTexture from 'images/craques-briques-single.svg';
import doorMan from 'images/doorman.svg';
import Bar from 'images/bar-facade.svg';
import BackgroundRecommancer from 'images/background-recommancer.svg';
import BarBarman from 'images/bar-barman.svg';
import BarBarmanShaking from 'images/bar-barman-shaking.svg';
import BarChiffre1 from 'images/bar-chiffre-1.svg';
import BarChiffre2 from 'images/bar-chiffre-2.svg';
import BarChiffre3 from 'images/bar-chiffre-3.svg';
import BarCocktailCoulisCeramique from 'images/bar-cocktail-coulis-ceramique.svg';
import BarCocktailFormalehyde from 'images/bar-cocktail-formalehyde.svg';
import BarCocktailIngredientBatterie from 'images/bar-cocktail-ingredient-batterie.svg';
import BarCocktailIngredientBoulesMites from 'images/bar-cocktail-ingredient-boules-mites.svg';
import BarCocktailIngredientDetergentToilette from 'images/bar-cocktail-ingredient-detergent-toilette.svg';
import BarCocktailIngredientDissolvantOngles from 'images/bar-cocktail-ingredient-dissolvant-ongles.svg';
import BarCocktailIngredientGoudron from 'images/bar-cocktail-ingredient-goudron.svg';
import BarCocktailIngredientLaveGlace from 'images/bar-cocktail-ingredient-lave-glace.svg';
import BarCocktailIngredientMercure from 'images/bar-cocktail-ingredient-mercure.svg';
import BarCocktailIngredientPesticide from 'images/bar-cocktail-ingredient-pesticide.svg';
import BarCocktailIngredientToluene from 'images/bar-cocktail-ingredient-toluene.svg';
import BarCocktailNickel from 'images/bar-cocktail-nickel.svg';
import BarCocktailPlomb from 'images/bar-cocktail-plomb.svg';
import BarTablet from 'images/bar-tablet.svg';
import BilletFumeur from 'images/billet-fumeur.svg';
import BilletVapoteur from 'images/billet-vapoteur.svg';
import Building from 'images/buildings-all.svg';
import Sky from 'images/ciel-etoiles.svg';
import RightLamp from 'images/lampadaire-droite.svg';
import LeftFlashingLamp from 'images/lampadaire.svg';
import Enseigne from 'images/neon-enseigne-bar.svg';
import Cloud1 from 'images/nuages-01.svg';
import Cloud2 from 'images/nuages-02.svg';
import Cloud3 from 'images/nuages-03.svg';
import Cloud4 from 'images/nuages-04.svg';
import RightPlant from 'images/plantes_droite.svg';
import LeftPlant from 'images/plantes_gauche.svg';
import Rock from 'images/roche-terre.svg';
import ScreenContestEnd from 'images/screen-contest-end.webp';
import VIPChain from 'images/vip-chain.svg';
import SplashScreen from 'images/logo-all.svg';
import TableChaise from 'images/table-chaises.svg';
import CadenasBarre from 'images/cadenas-barre.svg';
import HorizontalScroll from 'images/horizontal-scroll.svg';
import Frigo from 'images/frigo.svg';
import FrigoEnchainer from 'images/frigo-enchainer.svg';
import LuminaireSuspendu from 'images/luminaire-suspendu.svg';
import CadenasBoite from 'images/cadenas-boite.svg';
import Chain from 'images/chaine.svg';
import ConfetisGauche from 'images/confetis-gauche.svg';
import ConfetisDroite from 'images/confetis-droite.svg';
import MachineBrasCorde from 'images/machine-bras-corde.svg';
import MachineBrasTop from 'images/machine-bras-top.svg';
import MachineBras from 'images/machine-bras.svg';
import MachineBrasClose from 'images/machine-bras-close.svg';
import MachineBrasOpen from 'images/machine-bras-open.svg';
import MachineToutousBouton from 'images/machine-toutous-bouton.svg';
import MachineToutousComplete from 'images/machine-toutous-complete.svg';
import MachineToutousFlecheDroite from 'images/machine-toutous-fleche-droite.svg';
import MachineToutousFlecheGauche from 'images/machine-toutous-fleche-gauche.svg';
import MachineToutousOmbre from 'images/machine-toutous-ombre.svg';
import MachineToutous from 'images/machine-toutous.svg';
import MurBriques from 'images/mur-briques.svg';
import NeonQuizz from 'images/neon-quiz.svg';
import PorteMur from 'images/porte-mur-notext.svg';
import ToutouAnxiete from 'images/toutou-anxiete.svg';
import ToutouStress from 'images/toutou-stress.svg';
import ToutouTroublesCognitifs from 'images/toutou-troubles-cognitifs.svg';
import ToutouTroublesSommeil from 'images/toutou-troubles-sommeil.svg';
import MiroirFond from 'images/miroir-fond-mur.svg';
import Miroir from 'images/miroir.svg';
import PetitMiroir from 'images/miroir-small.svg';
import NicolePhase1 from 'images/Nicole-phase-1.jpg';
import NicolePhase2 from 'images/Nicole-phase-2.jpg';
import Cadenas from 'images/cadenas.png';
import EmojiMoney from 'images/emoji-money.png';
import ContestBackground1 from 'images/contest-background-1.png';
import ContestBackground2 from 'images/contest-background-2.png';
import ContestMainBackground from 'images/contest-main-background.jpg';
import StationCasseTete from 'images/station-casse-tete.svg';
import StationCocktail from 'images/station-cocktail.svg';
import StationDefi from 'images/station-defi.svg';
import StationFrigo from 'images/station-frigo.svg';
import StationMiroir from 'images/station-miroir.svg';
import StationQuiz from 'images/station-quiz.svg';
import StationSanteMentale from 'images/station-sante-mentale.svg';
import LogoDefi from 'images/logo-defi.svg';
import VapoteuseBatterie from 'images/vapoteuse-batterie.svg';
import VapoteuseBlank from 'images/vapoteuse-blank.svg';
import VapoteuseDripTip from 'images/vapoteuse-drip-tip.svg';
import VapoteuseLiquidPOD from 'images/vapoteuse-liquid-POD.svg';
import VapoteusePowerIndicator from 'images/vapoteuse-power-indicator.svg';
import VapoteuseTableAnimation from 'images/vapoteuse-table-animation.svg';
import ScreenVapeTablePersonnages from 'images/screen-vape-table-personnages.svg';
import ScreenVapeVapoteuse from 'images/screen-vape-vapoteuse.svg';
import CercleStress from 'images/cercle-stress.svg';
import BruitExterieur from 'sounds/bruit-exterieur.mp3';
import LoungeFar from 'sounds/lounge-far.mp3';
import Lounge from 'sounds/lounge.mp3';
import BartenderShaking from 'sounds/bartender-shaking.mp3';
import Boing from 'sounds/boing.mp3';
import ButtonClick from 'sounds/button-click.mp3';
import ClickVapoteuse from 'sounds/click-vapoteuse.mp3';
import Horn from 'sounds/horn.mp3';
import BombTick from 'sounds/bomb-tick.mp3';
import ChasseDEau from 'sounds/chasse-d-eau.mp3';
import MetalBreaking from 'sounds/metal-breaking.mp3';
import ToysAttrape from 'sounds/toys-attrape.mp3';
import ToysDeplacement from 'sounds/toys-deplacement.mp3';
import PresentialBackground from 'images/presentiel-bg.jpg';

export const CocktailSignSVG: string = CocktailSign;
export const CocktailMenthEurSVG: string = CocktailMenthEur;
export const CocktailNameMenthEurSVG: string = CocktailNameMenthEur;
export const CocktailNicotineOnTheRockSVG: string = CocktailNicotineOnTheRock;
export const CocktailNameNicotineOnTheRockSVG: string = CocktailNameNicotineOnTheRock;
export const CocktailLaVeuveNoireSVG: string = CocktailLaVeuveNoire;
export const CocktailNameLaVeuveNoireSVG: string = CocktailNameLaVeuveNoire;
export const CocktailLeRainbowShotSVG: string = CocktailLeRainbowShot;
export const CocktailNameLeRainbowShotSVG: string = CocktailNameLeRainbowShot;
export const CocktailLeSmokeyEyeSVG: string = CocktailLeSmokeyEye;
export const CocktailNameLeSmokeyEyeSVG: string = CocktailNameLeSmokeyEye;
export const CocktailZombieSVG: string = CocktailZombie;
export const CocktailNameZombieSVG: string = CocktailNameZombie;
export const DefisSVG: string = Defis;
export const SingleLogoSVG: string = SingleLogo;

export const SingleLogoPresentialSVG: string = SingleLogoPresential;

export const CoinSVG: string = Coin;
export const TitledCoinSVG: string = TitledCoin;
export const BilletSVG: string = Billet;
export const InterieurBarSVG: string = InterieurBar;
export const WallTextureSVG: string = wallTexture;
export const DoorManSVG: string = doorMan;
export const BackgroundRecommancerSVG: string = BackgroundRecommancer;
export const BarSVG: string = Bar;
export const BarBarmanSVG: string = BarBarman;
export const BarBarmanShakingSVG: string = BarBarmanShaking;
export const BarChiffre1SVG: string = BarChiffre1;
export const BarChiffre2SVG: string = BarChiffre2;
export const BarChiffre3SVG: string = BarChiffre3;
export const BarCocktailCoulisCeramiqueSVG: string = BarCocktailCoulisCeramique;
export const BarCocktailFormalehydeSVG: string = BarCocktailFormalehyde;
export const BarCocktailIngredientBatterieSVG: string = BarCocktailIngredientBatterie;
export const BarCocktailIngredientBoulesMitesSVG: string = BarCocktailIngredientBoulesMites;
export const BarCocktailIngredientDetergentToiletteSVG: string = BarCocktailIngredientDetergentToilette;
export const BarCocktailIngredientDissolvantOnglesSVG: string = BarCocktailIngredientDissolvantOngles;
export const BarCocktailIngredientGoudronSVG: string = BarCocktailIngredientGoudron;
export const BarCocktailIngredientLaveGlaceSVG: string = BarCocktailIngredientLaveGlace;
export const BarCocktailIngredientMercureSVG: string = BarCocktailIngredientMercure;
export const BarCocktailIngredientPesticideSVG: string = BarCocktailIngredientPesticide;
export const BarCocktailIngredientTolueneSVG: string = BarCocktailIngredientToluene;
export const BarCocktailNickelSVG: string = BarCocktailNickel;
export const BarCocktailPlombSVG: string = BarCocktailPlomb;
export const BarTabletSVG: string = BarTablet;
export const BilletFumeurSVG: string = BilletFumeur;
export const BilletVapoteurSVG: string = BilletVapoteur;
export const BuildingSVG: string = Building;
export const SkySVG: string = Sky;
export const RightLampSVG: string = RightLamp;
export const LeftFlashingLampSVG: string = LeftFlashingLamp;
export const EnseigneSVG: string = Enseigne;
export const Cloud1SVG: string = Cloud1;
export const Cloud2SVG: string = Cloud2;
export const Cloud3SVG: string = Cloud3;
export const Cloud4SVG: string = Cloud4;
export const RightPlantSVG: string = RightPlant;
export const LeftPlantSVG: string = LeftPlant;
export const RockSVG: string = Rock;
export const ScreenContestEndWEBP: string = ScreenContestEnd;
export const VIPChainSVG: string = VIPChain;
export const SplashScreenSVG: string = SplashScreen;
export const TableChaiseSVG: string = TableChaise;
export const CadenasBarreSVG: string = CadenasBarre;
export const HorizontalScrollSVG: string = HorizontalScroll;
export const FrigoSVG: string = Frigo;
export const FrigoEnchainerSVG: string = FrigoEnchainer;
export const LuminaireSuspenduSVG: string = LuminaireSuspendu;
export const CadenasBoiteSVG: string = CadenasBoite;
export const ConfetisGaucheSVG: string = ConfetisGauche;
export const ConfetisDroiteSVG: string = ConfetisDroite;
export const ChainSVG: string = Chain;
export const MachineBrasSVG: string = MachineBras;
export const MachineBrasCloseSVG: string = MachineBrasClose;
export const MachineBrasOpenSVG: string = MachineBrasOpen;
export const MachineBrasCordeSVG: string = MachineBrasCorde;
export const MachineBrasTopSVG: string = MachineBrasTop;
export const MachineToutousBoutonSVG: string = MachineToutousBouton;
export const MachineToutousCompleteSVG: string = MachineToutousComplete;
export const MachineToutousFlecheDroiteSVG: string = MachineToutousFlecheDroite;
export const MachineToutousFlecheGaucheSVG: string = MachineToutousFlecheGauche;
export const MachineToutousOmbreSVG: string = MachineToutousOmbre;
export const MachineToutousSVG: string = MachineToutous;
export const MurBriquesSVG: string = MurBriques;
export const NeonQuizzSVG: string = NeonQuizz;
export const PorteMurSVG: string = PorteMur;
export const ToutouAnxieteSVG: string = ToutouAnxiete;
export const ToutouStressSVG: string = ToutouStress;
export const ToutouTroublesCognitifsSVG: string = ToutouTroublesCognitifs;
export const ToutouTroublesSommeilSVG: string = ToutouTroublesSommeil;
export const MiroirFondSVG: string = MiroirFond;
export const MiroirSVG: string = Miroir;
export const PetitMiroirSVG: string = PetitMiroir;
export const NicolePhase1JPG: string = NicolePhase1;
export const NicolePhase2JPG: string = NicolePhase2;
export const LogoDefiSVG: string = LogoDefi;
export const CadenasPNG: string = Cadenas;
export const ContestBackground1PNG: string = ContestBackground1;
export const ContestBackground2PNG: string = ContestBackground2;
export const ContestMainBackgroundJPG: string = ContestMainBackground;
export const EmojiMoneyPNG: string = EmojiMoney;
export const StationCasseTeteSVG: string = StationCasseTete;
export const StationCocktailSVG: string = StationCocktail;
export const StationFrigoSVG: string = StationFrigo;
export const StationMiroirSVG: string = StationMiroir;
export const StationQuizSVG: string = StationQuiz;
export const StationSanteMentaleSVG: string = StationSanteMentale;
export const StationDefiSVG: string = StationDefi;
export const VapoteuseBatterieSVG: string = VapoteuseBatterie;
export const VapoteuseBlankSVG: string = VapoteuseBlank;
export const VapoteuseDripTipSVG: string = VapoteuseDripTip;
export const VapoteuseLiquidPODSVG: string = VapoteuseLiquidPOD;
export const VapoteusePowerIndicatorSVG: string = VapoteusePowerIndicator;
export const VapoteuseTableAnimationSVG: string = VapoteuseTableAnimation;
export const ScreenVapeTablePersonnagesSVG: string = ScreenVapeTablePersonnages;
export const ScreenVapeVapoteuseSVG: string = ScreenVapeVapoteuse;
export const CercleStressSVG: string = CercleStress;

export const BruitExterieurMP3: string = BruitExterieur;
export const LoungeFarMP3: string = LoungeFar;
export const LoungeMP3: string = Lounge;
export const BartenderShakingMP3: string = BartenderShaking;
export const BoingMP3: string = Boing;
export const BombTickMP3: string = BombTick;
export const ButtonClickMP3: string = ButtonClick;
export const ClickVapoteuseMP3: string = ClickVapoteuse;
export const HornMP3: string = Horn;
export const ChasseDEauMP3: string = ChasseDEau;
export const MetalBreakingMP3: string = MetalBreaking;
export const ToysAttrapeMP3: string = ToysAttrape;
export const ToysDeplacementMP3: string = ToysDeplacement;

export const PresentialBackgroundJPEG: string = PresentialBackground;
